<template>
  <div class="flex flex-col bg-white">
    <PageHeader title="About Cornfield Engineering" sub_title="" img="/images/about_header.webp" />
    <div class="container mx-auto flex flex-wrap py-16">
      <div class="flex justify-center w-1/2 p-2 my-2">
        <img src="/images/chris_profile_img.png" alt="" />
      </div>
      <div class="flex flex-col w-1/2 p-2 my-2 ">
        <p class="mb-2 text-lg text-gray-900">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet
          quisquam quis qui! Voluptatem, placeat dicta.
        </p>
        <p class="mb-2 text-lg text-gray-900">
          Perspiciatis omnis quas, dignissimos hic voluptas odio itaque aut fuga molestias mollitia
          unde saepe illo, eveniet quisquam quis qui! Voluptatem, placeat dicta.Lorem ipsum dolor
          sit amet consectetur adipisicing elit.
        </p>
        <p class="mb-2 text-lg text-gray-900">
          Voluptatem, placeat dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Perspiciatis omnis quas, dignissimos hic voluptas odio itaque aut fuga molestias mollitia
          unde saepe illo, eveniet quisquam quis qui!
        </p>
        <p class="mb-2 text-lg text-gray-900">
          Litaque aut fuga molestias mollitia unde saepe illo rorem ipsum dolor sit amet consectetur
          adipisicing elit. Perspiciatis omnis quas, dignissimos hic voluptas odio, eveniet quisquam
          quis qui! Voluptatem, placeat dicta.
        </p>
      </div>
      <div class="w-full my-2">
        <p class="mb-2 text-lg text-gray-900">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet
          quisquam quis qui! Voluptatem, placeat dicta.
        </p>
        <p class="mb-2 text-lg text-gray-900">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet
          quisquam quis qui! Voluptatem, placeat dicta.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader";

export default {
  name: "About",
  components: { PageHeader },
};
</script>
